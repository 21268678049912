@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "./assets/styles/mixins";
@import "./assets/styles/forms";
@import "./assets/styles/social-links-svg";
@import "./assets/styles/user/index";
@import "./assets/styles/new-faq/index";
@import "./assets/styles/pages/index";
@import "./assets/styles/profile/index";
@import "./assets/styles/freeway/index";
@import "./assets/styles/total-premium/index";
@import "./assets/styles/residential/index";
@import "./assets/styles/header-logo/index";
@import "./assets/styles/cookiebanner";
@import "./assets/styles/tenant-boarding/index";
@import "./assets/styles/file-dnd/index";
@import "./assets/styles/mdc";
@import 'app/style/common';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import './assets/styles/custom-sites/bozzuto';


* {
  margin: 0;
  padding: 0;
}

:root {
  --root-font-family: 'Poppins', serif;
}

a, a:visited {
  color: #2f3542;
}

.cursor-pointer:hover {
  cursor: pointer;
}

app-main { width: 100%; overflow: hidden; }
app-main-landing, app-main-navigation { width: 100%; }

html {
  height: 100%;
  scrollbar-gutter: stable;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: var(--root-font-family);
  font-size: 16px;
  color: #2f3542;
  //overflow-x: hidden;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
hr {
  border-top: 1px solid #ced6e0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}





.app-header {
  height: 70px;
  padding: 30px 30px 50px 30px;
  position: relative;
}
.header-nav-profile {
  max-width: 50px;
}
.header-main-nav ul {
  display: flex;
}
/* MAIN CONTENT */
.content-container {
  width: 100%;
  flex: 1 0 auto;
}
.main-content {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
}
.profile-background {
  background-color: #f1f2f6;
  &_freeway { margin-bottom: -108px; }
}
.top-lang-switcher {
  height: 55px;
  label {
    display: none !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline__leading,
  .mdc-text-field--outlined .mdc-notched-outline__notch,
  .mdc-text-field--outlined .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }
}
.app-footer {
  height: 438px;
  flex: 0 0 auto;
  background-color: #2f3542;
}
app-user, app-st-elements{
  width: 100%;
}
app-faq-page,
app-giveback-page,
app-privacy-policy-page,
app-terms-of-use-page,
app-about-us-page,
app-contact-us-page,
app-pci-insurance-fraud-provisions,
app-insurance-producer-licensure {
  display: flex;
  margin: 10px auto;
  padding: 25px;
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 10px -3px #dcdde2;
  background: #fff;
}
app-login, app-register,
app-forgot-password, app-change-password, app-claims-from-page, app-accept-invitation{
  display: flex;
  margin: 50px auto;
  padding: 35px;
  box-sizing: border-box;
  width: 700px;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 10px -3px #dcdde2;
  background: #fff;
}
app-profile, app-claims,
app-policies, app-policies-view,
app-claims-new, app-leases,
app-invoices, app-invoice-details,
app-claims-details, app-upload-coverage-proof,
app-coverage-proof, app-email-notifications,
app-user-change-password, app-session-list,
app-payment-methods, app-coverage-proof,
app-invoice-details, app-policies-view, app-claims-details {
  flex-grow: 1;
}
.hide-block {
  display: none !important;
  opacity: 0 !important;
  z-index: -100 !important;
}
.drop-login-quote {
  display: flex;
  flex-direction: column;
  width: 100%;
}
  /*  Invalid */
.is-invalid {
  border: 1px solid red;
}
.invalid-input {
  color:red;
}
.error-message {
  position: absolute;
  font-size: 12px;
  bottom: -20px;
  color: red;
  z-index: 100;
}

/* Poppins normal */

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Poppins medium */
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

/* Poppins bold */
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Text Inputs */
.text-input {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.datapicker-input {
  .datapicker-input_mat {
    top: 5px;
  }
}

.select-input {
  position: relative;
}

.text-input label,
.select-input label {
  font-weight: 400;
  margin-top: 3px;
  margin-right: 10px;
  color: #2f3542;
  z-index: 3;
  min-width: 130px;
}

.text-input input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ced6e0;
  color: #2f3542;
  font-weight: bold;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
}


.half-input-size {
  width: 47% !important;
  position: relative !important;
}

.third-input-size {
  width: 30%;
  position: relative;
}

.half-input-size img,
.registration-date-input img,
.third-input-size img {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  bottom: 12px;
  z-index: 100;
}

/* Auth form style*/

.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

app-residential, app-commercial, .guarantee_wrap, app-confirm-page{
  position: relative;
  max-width: 690px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

app-process-payment, app-total-premium {
  position: relative;
  max-width: 690px;
  width: 100%;
  margin: 20px auto 0;
  min-height: 500px;
  display: block;
}

app-confirm-page {
  width: 800px;
}


app-guarantee {
  width: 100%;
}

.guarantee_form_fxlayout {
  display: flex;
  flex-direction: row;
}


.residential-background {
  position: absolute;
  width: 100%;
  height: 500px;
  top: 301px;
  overflow: hidden;
  z-index: 0;
}

.left-house {
  position: absolute;
  width: 418px;
  height: 100%;
  background-image: url('~/assets/images/left-buildings.png');
  background-repeat: no-repeat;
  background-attachment: local;
  left: 0;
  top: 0;
}

.right-house {
  position: absolute;
  width: 418px;
  height: 100%;
  background-image: url('~/assets/images/right-buildings.png');
  background-repeat: no-repeat;
  right: 0;
  top: 0;
}

.vertical-line-top {
  width: 2px;
  height: 35px;
  background-image: url('~/assets/images/vertival-line.png');
  background-repeat: no-repeat;
  margin: 55px auto;
}

.vertical-line-middle {
  width: 2px;
  height: 68px;
  background-image: url('~/assets/images/vertival-line.png');
  background-repeat: no-repeat;
  margin: 0 auto;
}

.vertical-line-middle-second {
  width: 2px;
  height: 118px;
  background-image: url('~/assets/images/vertival-line.png');
  background-repeat: no-repeat;
  margin: 0 auto;
}


.vertical-line-bottom {
  position: absolute;
  bottom: -180px;
  width: 1.5px;
  height: 118px;
  background-image: url('~/assets/images/vertival-line.png');
  background-repeat: no-repeat;
  margin: 0 auto;
}


.right-background-line {
  position: absolute;
  width: 450px;
  height: 60%;
  background-image: url('~/assets/images/right-background-line.png');
  background-repeat: no-repeat;
  right: 210px;
  top: 133px;
}

.left-background-line {
  position: absolute;
  width: 418px;
  height: 60%;
  background-image: url('~/assets/images/left-background-line.png');
  background-repeat: no-repeat;
  left: 285px;
  top: 133px;
}

/* Form */
.residential-form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.residential-form-main {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 45px 40px 45px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px -1px #dcdde2;
  background-color: #fff;
}
.section-gap {
  position: relative;
  width: 100%;
}
.section-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 15px;
  &_insured-details { margin-top: 20px; }
}
.add-button-block {
  display: flex;
  justify-content: space-between;
}
.add-button {
  width: 160px;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #01aae1;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.registration-input-folded {
  content: url('~/assets/images/arrow.svg');
}

.registration-input-detailed {
  content: url('~/assets/images/arrow-up.svg');
}

.registration-accent {
  font-weight: bold;
  color: #00abe2;

  &_freeway {
    color: var(--secondary-color);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.58px;
    line-height: 49px;

    .underline { display: none; }
  }
}

.text-residential-content,
.text-covered-content {
  text-align: center;
  box-sizing: border-box;
  margin: 25px 0;
}

.residential-content-description {
  margin-top: 15px;
  font-size: 18px;
}

.residential-large-text {
  color: #1b75ba;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 38px;
  & p { margin-bottom: 15px; }
}

.residential-large-text span {
  position: relative;
  font-size: 44px;
  white-space: nowrap;
}

.text-residential-content .underline {
  position: absolute;
  bottom: 0px;
  width: 200px;
  max-width: 100%;
}

.option-icon-block {
  min-width: 27px;
  width: 27px;
  height: 25px;
  margin: auto 0;
}

.option-icon-white {
  background: url('~/assets/images/checkbox_sprites.png') 2px -54px no-repeat;
}
.option-icon-grey {
  background: url('~/assets/images/checkbox_sprites.png') 2px 1px no-repeat;
}
.option-icon-blue {
  background: url('~/assets/images/checkbox_sprites.png') 2px -25.5px no-repeat;
}

.success-msg {
  color: #51A351;
}
.right-house {
  top: 0;
  right: 0;
  width: 418px;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}
.spinner-lg:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}
a.gc-link {
  color: #00abe2;
  cursor: pointer;
  text-decoration: underline;
  text-transform: capitalize;
}

.reg-buttons-gap {
  margin-top: 40px;
}

app-guarantee-calculator {
  display: block;
  height: 100%;
}

.mat-mdc-checkbox-layout { white-space: normal !important; }

.checkbox-layout-custom {
  margin-right: 15px;
}

.form-descriptor-tooltip {
  font-size: 14px;
}

.date-with-suffix {
  .mat-mdc-form-field-prefix,
  .mat-mdc-form-field-suffix {
    .suffix-container {
      display: inline-flex;
      align-items: center;
      .mat-icon {
        line-height: 1;
      }
    }
  }
}


.guarantee2_header {
  font-size: 28px;
  margin-top: 42px;
  margin-bottom: 58px;
  color: var(--primary-color);
  text-align: center;
}

.info-update-block__button {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: #2E308F;
}
.info-update__container {
  width: 100%;
}
.guarantee2-form-container {
  display: flex;
  form {
    flex-grow: 1;
  }
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.guarantee__btns-container { margin-top: 15px; }
.st-elements__btns-container {
  margin-top: 15px;
  .mat-btn {
    padding: 8px 16px;
  }
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.premium-mobile-placeholder {
  display: none;
}

.estimate-premium {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  width: 290px;
  max-height: 280px;
  padding: 20px;
  margin-left: 30px;
  z-index: 10;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
}

.cdk-overlay-pane.disclosures {
  .mat-mdc-dialog-content {
    margin-right: -6px;
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #f1f1f1;
    }
  }
}

.cdk-overlay-pane.questions {
  max-width: 700px!important;

  .mat-mdc-dialog-container {
    padding: 0;
    overflow: visible;
  }

  .mat-mdc-dialog-content {
    margin-right: -6px;
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #f1f1f1;
    }
  }
}

.error {
  color: var(--warning-color);
}

.mat-mdc-form-field-error {
  margin-top: 4px;
  &.tight {
    margin-top: -2px;
  }
}

/* MEDIA QUERIES */
@media screen and (max-width: 579px) {
  body {
    font-size: 14px;
  }

  app-payment-methods, app-coverage-proof, app-invoice-details, app-policies-view, app-claims-details{
    max-width: none;
  }

  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95px;
    margin-bottom: 22px;
    box-sizing: border-box;
    padding: 5px 22px 5px 32px;
    border-radius: 5px;
    border: 0;
    background-color: #ffffff;
  }

  .form-container {
    flex-direction: column-reverse;
  }

  .my-profile-form,
  .claim-content,
  .add-claims-form,
  .payment-methods,
  .leases-content {
    width: 100%;
    margin-top: 22px;
  }

  .claim-content {
    align-items: center;
    text-align: center
  }

  .app-header {
    height: 65px;
    padding: 30px 20px;
  }

  .login-register {
    display: none !important;
  }


  .header-content {
    justify-content: unset;
  }

  li.hide-block {
    display: block !important;
    opacity: 1 !important;
    z-index: 100 !important;
  }


  .drop-login-quote {
    margin: 0 !important;
  }

  .mobile-list a {
    color: #fff !important;
  }

  .add-list ul li:first-child {
    margin-top: 0 !important;
  }

  .add-list ul li:last-child {
    margin-top: 30px;
    height: 70px;
  }


  .what-is-cover {
    height: 300px;
    margin-top: 60px;
    align-items: center;
  }

  .better-covered-description {
    font-size: 14px;
    padding: 0;
    line-height: 28px;
  }

  .residential-form-main {
    padding: 18px 20px 10px 20px;
  }

  .residential-content {
    position: relative;
    max-width: 95%;
    width: unset;
    margin-top: unset;
  }

  .residential-tabs-block {
    margin-top: 15px;
  }

  .insurance-name {
    max-width: 40%;
    margin-right: 10px;
  }

  .residential-large-text,
  .residential-large-text span {
    font-size: 20px;
  }

  .residential-content-description {
    margin-top: 5px;
    font-size: 15px;
  }

  .text-covered-content {
    margin: 0 20px;
  }


  .app-footer {
    height: 870px;
  }

  .footer-content {
    padding-top: 40px;
  }

  .footer-nav {
    flex-direction: column;
  }

  .footer-links {
    width: unset;
    margin-top: 30px;
  }

  .footer-links-container {
    flex-direction: column;
    max-width: 100%;
  }

  .footer-description {
    flex-direction: column-reverse;
    padding-top: 15px;
  }

  .social,
  .policy-terms,
  .rights-reserved {
    margin-top: 30px;
  }

  .social {
    justify-content: unset;
    width: 250px;
  }

  .social-link {
    position: relative;
    right: 7px;

  }

  .policy-terms {
    justify-content: unset;
    width: 90%;
  }

  .span-space {
    width: 15px;
  }

  .rights-reserved {
    justify-content: unset;
    width: unset;
  }

  .half-input-size,
  .third-input-size,
  .state-input-select{
    width: 100% !important;
  }

  .half-input-size:nth-child(2n),
  .third-input-size:not(:first-child) {
    margin-top: 30px;
  }

  .limits-add-ons {
    flex-direction: column;
  }

  /* rework img */
  .fits-commercial,
  .fits-residential {
    display: none;
  }


  .mobile-bottom-0 {
    margin-bottom: 0;
  }

  .section-title {
    &_insured-details { margin-top: 30px; }
  }

  .it-fits {
    margin-top: -10px;
    padding: 0 10px;
    height: 320px;
  }

  .fits-everyone {
    justify-content: unset;
  }

  .everyone-block {
    margin-top: 58px;
    font-size: 28px;
  }

  .type-of-product:first-child .product-title,
  .type-of-product:nth-child(2) .product-title,
  .product-title {
    margin-top: 20px !important;
  }

  .product-description {
    margin-top: 20px;
  }


  .text-covered-content p:first-child {
    padding: 0 10px;
  }
  .inline-input-items {
    display: block !important;

    mat-form-field {
      display: block !important;
      margin-right: 0 !important;
    }
  }
}
@media screen and (max-width: 798px) {
  .profile-menu ul li {
    margin-bottom: 10px;
  }
  .residential-form-main {
    padding: 18px 20px 10px 20px;
  }

  app-residential,
  app-commercial{
    position: relative;
    max-width: 95%;
    width: unset;
    margin-top: unset;
  }

  .residential-tabs-block {
    margin-top: 15px;
  }

  .insurance-name {
    max-width: 40%;
    margin-right: 10px;
  }

  .residential-large-text,
  .residential-large-text span {
    font-size: 20px;
  }

  .residential-content-description {
    margin-top: 5px;
    font-size: 15px;
  }

  .text-covered-content {
    margin: 0 20px;
  }

  .it-fits {
    padding: 0 15px;
  }

  .payment-price {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 920px) {

  .policies-content,
  .invoices-content {
    width: 100%;
  }

  .covered-product {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .vertical-line-middle-second {
    height: 60px;
  }

  .type-of-product {
    width: unset;
    max-width: unset;
    margin-bottom: 40px;
  }

  .type-of-product:last-child {
    margin-bottom: 0px;
  }

  .it-fits {
    padding: 0 25px;
  }
}
@media screen and (max-width: 960px) {
  body.no-scroll {
    overflow: hidden;
  }
  app-residential, app-commercial, .guarantee_wrap, app-confirm-page {
    margin-top: 0;
  }
}
@media screen and (max-width: 1079px) {
  .header-main-nav {
    display: none;
  }
}
@media only screen and (max-width: 1099px) {
  .guarantee_form_fxlayout {
    flex-direction: column;
  }
  .guarantee_component_wrap {
    margin-top: 30px;
  }


  .premium-mobile-placeholder {
    display: block;
    height: 50px;
  }
}
@media only screen and (min-width: 1100px) {
  .residential-form {
    width: 690px;
  }
  .residential-form-main {
    width: 690px;
  }
  .guarantee_component_wrap {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1100px) and (max-width:1500px) {
  .guarantee_wrap.with_calculator {
    width: 1000px;

    .guarantee_form_wrap {
      //width: 690px;
    }

    .residential-form-main {
      width: 690px;
    }

    .guarantee_component_wrap {
      margin-left: 10px;
    }

  }
}
@media screen and (max-width: 1199px) {

  .footer-description,
  .footer-nav {
    margin-left: 15px;
    margin-right: 15px;
  }

  .right-house,
  .left-house {
    display: none;
  }

  app-switch-languages {
    display: none!important;
  }
}

@media screen and (max-width: 1279px) {
  .estimate-premium {
    width: 100%;
    padding: 0;
    margin-left: 0;
    top: 0;
    height: auto;
    border-radius: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1394px) {
  .right-background-line {
    display: none;
  }

  .left-background-line {
    display: none;
  }
}

.mat-field-with-loader {
  position: relative;
  mat-form-field {
    width: 100%
  }
  .spinner {
    width: 100%;
    position: absolute;
    top: 35px;
  }
}


@media screen and (max-width: 1390px) {
  .long-menu {
    display: none;
  }
}


/* MEDIA QUERIES END. PLACE STYLES BEFORE THIS BLOCK */
